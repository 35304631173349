.info-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../../public/secureyou.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
}

.info-page-header {
  // font-family: "Press Start 2P", cursive;
  display: flex;
  margin-top: 50px;
  margin-left: 8%;
  font-size: 60px;
  font-weight: bolder;
  color: #648eb3 !important;
  filter: blur(1px);
}

.info-page-textBox {
  align-self: flex-start;
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 35px;
  margin-top: 1rem;
  position: relative;
  text-align: center;
  margin-left: 1rem;
  width: 50%;
  box-shadow: 5px 0px 30px black;
  border-radius: 2rem;
}
.info-page-textBox2 {
  align-self: flex-start;
  margin-left: 1rem;

  font-size: 35px;
  margin-top: 1rem;
  position: relative;
  text-align: center;
  width: 50%;
  box-shadow: 5px 0px 30px black;
  border-radius: 2rem;
  margin-bottom: 1rem;
}
.info-page-h3 {
  color: black;
  text-shadow: 1px 1px 4px black;
}

.info-page-span {
  font-size: 24px;
  // font-family: "Open Sans", sans-serif;
  color: black;
  text-shadow: 0px 0px 1px black;
  line-height: 1.5;
  margin: 0 auto;
}

.info-page-imagebox {
  margin-right: 100px;
  z-index: 99;
  display: none;
}

.info-page-imagebox2 {
  position: absolute;
  right: 0;
  top: -9%;
  z-index: -1;
  height: 100px;
}

.info-types-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  z-index: 9;
}
.info-types-single-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
}

.info-types-headers {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 1px 1px 4px black;
  padding: 20px 0;
  color: white;
}

.info-types-spans {
  font-size: 18px;
  // font-family: "Open Sans", sans-serif;
  color: #cdd6f6;
  font-weight: bold;
  text-shadow: 1px 1px 3px black;
  text-align: center;
}
.info-page-textBox2 {
  // margin-top: 50px;
  position: relative;
  text-align: center;
  width: 50%;
  box-shadow: 5px 0px 30px black;
  border-radius: 2rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
  .info-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // visibility: hidden;
  }

  .info-page-header {
    font-size: 2.5rem;
  }

  .info-page-textBox {
    // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    // margin-top: 50px;
    position: relative;
    text-align: center;
    box-shadow: 5px 0px 30px black;
    border-radius: 2rem;
    margin-left: 0;
    width: 90%;
    align-self: center;
  }
  .info-page-textBox2 {
    font-size: 1.5rem;
    // margin-top: 50px;
    position: relative;
    text-align: center;
    box-shadow: 5px 0px 30px black;
    border-radius: 2rem;
    margin-bottom: 1rem;
    margin-left: 0;
    width: 90%;
    align-self: center;
  }
  .info-page-h3 {
    color: black;
    text-shadow: 1px 1px 4px black;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .info-page-span {
    font-size: 18px;
    text-align: center;
    z-index: 11;
    width: 90%;
    margin: 0 auto;
  }

  .info-page-imagebox {
    z-index: 99;
    display: none;
  }

  .info-page-imagebox2 {
    height: 100px;
  }

  .info-types-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 20px;
    z-index: 9;
    margin: 0 auto;
    margin-top: 40px;

    div {
      margin-bottom: 30px;
    }
  }

  .info-types-headers {
    // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-shadow: 1px 1px 4px black;
    color: white;
    font-size: 16px;
    padding-top: 2px;
    text-align: center;
  }

  .info-types-spans {
    font-size: 14px;
    // font-family: "Open Sans", sans-serif;
    color: black;
    font-weight: bold;
    text-shadow: 0px 0px 1px black;
    text-align: center;
  }
}
