.about-us-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-us-page-header {
  // font-family: "Press Start 2P", cursive;
  display: flex;
  margin-top: 50px;
  margin-left: 8%;
  font-size: 60px;
  font-weight: bolder;
  color: #648eb3 !important;
  filter: blur(1px);
}
.about-us-images-wrapper {
  margin-right: 20px;
}
.about-us-types-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-top: 100px;
  z-index: 9;
}
.about-us-types-container-reverse {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-top: 100px;
  z-index: 9;
}

.about-us-types-headers {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 1px 1px 4px black;
  font-size: 42px;
  color: white;
}

.about-us-types-spans {
  font-size: 20px;
  // font-family: "Open Sans", sans-serif;
  color: #cdd6f6;
  font-weight: bold;
  text-shadow: 1px 1px 3px black;
}

.about-us-buttons {
  color: white !important;
  font-size: 21px !important;
  text-shadow: 2px 2px 4px rgb(0, 0, 0) !important;
  width: 300px !important;
}

.about-us-buttons:hover {
  font-weight: bold;
  width: 500px !important;
  background-color: #648eb3 !important;
}

.about-us-page-h3 {
  color: white;
  font-size: 40px !important;

  text-shadow: 1px 1px 4px black;
}

@media only screen and (max-width: 667px) {
  .about-us-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    align-items: center;
    text-align: center;
    margin-top: 10%;

    .about-us-types-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 9;
      margin-top: 60px;
    }
    .about-us-types-container-reverse {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      z-index: 9;
    }
    .about-us-page-header {
      font-size: 30px;
      text-align: center;
      margin: 50px 50px auto;
    }

    .about-us-types-headers {
      font-size: 30px;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .about-us-types-spans {
      font-size: 17px;
    }

    .about-us-text-container {
      margin: 0 auto;
    }
    .risk-text {
      margin-bottom: 20px;
    }
  }
}
