.admin-users {
  background-color: #141b2d;
  border-left: 1px solid white;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  // border-bottom: 1px solid white;
}
.admin-users-table {
  // margin-top: 50px;
}
