// @media only screen and (max-width: 667px) {
//   .footer-container {
//     visibility: hidden;
//   }
// }
.footer-container {
  color: #7f8389 !important;
  position: relative;
  width: 100%;
  margin-top: 20px;
  height: 130px;
  z-index: 99;
  .footer-inner-container {
    margin-top: 5px;
    .grid-inner-container {
      text-align: center;
      p {
        text-shadow: 0px 2px 7px black;
        // font-family: Open Sans, sans-serif;
        color: #848485;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
      }
      .footer-strong {
        margin-top: 10px;
      }
    }
  }
  .footer-container-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .footer-container-link {
      font-size: 14px;
      color: white;
      cursor: pointer;
      text-shadow: 0px 0px 7px black;
      // font-family: Open Sans, sans-serif;
      color: #848485;
      font-weight: bold;
    }
  }
  .location-box-container {
    text-align: center;
    margin-top: 10px;
    span {
      font-size: 13px;
      margin-right: 5px;
      padding-right: 5px;
      color: white;
      text-shadow: 0px 0px 7px black;
      // font-family: Open Sans, sans-serif;
      color: #848485;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 667px) {
  .footer-container {
    // visibility: hidden;

    .footer-container-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
